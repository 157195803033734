const moment = require("moment");

function getStartOfDayToISOString(date) {
  return getStartOfDay(date).format();
}

function getStartOfDay(date) {
  return moment(date).startOf("day");
}

function format(date, format) {
  return moment(date).format(format);
}

export default {
  getStartOfDayToISOString,
  getStartOfDay,
  format
};
